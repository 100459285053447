import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { HomePageActions } from "../../Store/Home/action";

function PortfolioTab() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({ type: HomePageActions.GET_HOME_STUDENTPORTFOLIO });
  }, [dispatch]);
  const { StudentPortfolio } = useSelector((state) => state.HomePageReducer);
  const { state } = useLocation();

  const [currentTab, setCurrentTab] = useState("tab-1");
  const [animateHeading, setAnimateHeading] = useState(false);
  const [animatePortfolio, setAnimatePortfolio] = useState(false);
  const headingRef = useRef(null);
  const [hasScrolledIntoView, setHasScrolledIntoView] = useState(false);

  const handleTextHover = (tabId) => {
    setCurrentTab(tabId);
  };
  const handleTextClick = (tabId) => {
    // setCurrentTab(tabId === currentTab ? "" : tabId);
    setCurrentTab(tabId);
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (!hasScrolledIntoView) {
            setAnimateHeading(true);
            setHasScrolledIntoView(true);
          }
        }
      });
    }, options);

    if (headingRef.current) {
      observer.observe(headingRef.current);
    }

    // Cleanup
    return () => {
      if (headingRef.current) {
        observer.unobserve(headingRef.current);
      }
    };
  }, [hasScrolledIntoView]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (!hasScrolledIntoView) {
            setAnimatePortfolio(true);
            setHasScrolledIntoView(true);
          }
        }
      });
    }, options);

    if (headingRef.current) {
      observer.observe(headingRef.current);
    }

    // Cleanup
    return () => {
      if (headingRef.current) {
        observer.unobserve(headingRef.current);
      }
    };
  }, [hasScrolledIntoView]);

  return (
    <section
      className="portfolio-tab crev section-padding"
      data-scroll-index="3"
    >
      <div className="container mt-70">
        <div className="sec-head mb-80">
          <h6 className="sub-title main-color mb-25">Student Portfolio</h6>
          <div className="bord pt-25 bord-thin-top d-flex align-items-center">
            <h2
              ref={headingRef}
              className={`fw-600 ls1 d-rotate wow ${
                animateHeading ? "heading-animation" : ""
              }`}
            >
              <span className="rotate-text portfolio-text">
                Our Student Working on
              </span>
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-5 d-flex align-items-center justify-content-center">
            <div className="glry-img">
              {StudentPortfolio?.map((item, index) => (
                <div
                  id={`tab-${index + 1}`}
                  className={`bg-img tab-img ${
                    currentTab === `tab-${index + 1}` ? "current" : ""
                  }`}
                  style={{ backgroundImage: `url(${item.thumbnail})` }}
                ></div>
              ))}
            </div>
          </div>
          <div className="col-lg-6 offset-lg-1 content portfolio-tabss">
            {StudentPortfolio?.map((item, index) => (
              <div
                className={`cluom mb-30 current ${
                  animateHeading ? "heading-animation" : ""
                }`}
                data-wow-delay=".2s"
                data-tab={`tab-${index + 1}`}
                onMouseEnter={() => handleTextHover(`tab-${index + 1}`)}
                onClick={() => handleTextClick(`tab-${index + 1}`)}
                style={{
                  backgroundImage:
                    currentTab === `tab-${index + 1}` &&
                    window.innerWidth <= 768
                      ? `url(${item.thumbnail})`
                      : "",
                  backgroundSize: "cover",
                  objectPosition: "center",
                  border: "none",
                }}
              >
                <div className="info">
                  <h6
                    className={`sub-title opacity-7 fadeInUp${
                      animatePortfolio ? " portfolio-tab" : ""
                    }`}
                  >
                    {item.studentname}
                  </h6>
                  <h4
                    className={`stu-proj-name fadeInUp${
                      animatePortfolio ? " portfolio-tab" : ""
                    }`}
                  >
                    {item.projectname}
                  </h4>
                </div>

                <div className="more text-u ls1 fz-12">
                  <a
                    onClick={() => {
                      navigate("/ProjectDetail", {
                        state: { projectId: item.id },
                      });
                    }}
                  >
                    View Project
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="flr mt-30">
          <a href="/StudentsProjectLists" className="go-more">
            <span className="text">View more </span>
            <span className="icon ti-arrow-circle-right portfolio-text"></span>
          </a>
        </div>
      </div>
    </section>
  );
}

export default PortfolioTab;
