import React, { useState } from "react";
import SideNavigation from "../../Menu-sidenavbar/SideNavigation ";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import HeaderBackground from "../../../common/SubpagesBGimage";

function Programs() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [openKeys, setOpenKeys] = useState([]);
  const [currentTab, setCurrentTab] = useState("tab-1");
  const location = useLocation();
  const dispatch = useDispatch();

  const routeNames = {
    "/programs": { menuName: "Pedagogy", subMenuName: "Programs" },
  };

  const { menuName, subMenuName } =
    location.state || routeNames[location.pathname] || {};

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  const handleMouseEnter = (tab) => {
    setCurrentTab(tab);
  };

  // useEffect(() => {
  //   dispatch({ type: ProgramsActions.GET_PROGRAMS });
  // }, [dispatch]);

  const { ProgramsData } = useSelector((state) => state.ProgramsReducer);

  const pedagogyDescription = ProgramsData?.pedagogy?.[0]?.program || "";

  const accordionItems = Array.isArray(ProgramsData.our_programs)
    ? ProgramsData.our_programs.map((program, index) => ({
        id: `tab-${index + 1}`,
        title: program.prog_name,
        content: program.prog_content,
      }))
    : [];

  const images = Array.isArray(ProgramsData.our_programs)
    ? ProgramsData.our_programs.reduce((acc, program, index) => {
        acc[`tab-${index + 1}`] = program.prog_image;
        return acc;
      }, {})
    : {};

  const toggleAccordion = (index) => {
    setActiveAccordion((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div>
      <HeaderBackground
        menuName={menuName}
        subMenuName={subMenuName}
        handleMenuToggle={handleMenuToggle}
      />
      <section
        className="portfolio-tab crev section-padding"
        data-scroll-index="3"
      >
        <div className="container position-re prgrms">
          <div className="sec-head mb-80">
            <div className="bord pt-25 bord-thin-top d-flex align-items-center">
              <h4 className="fw-600 text-u ls1 pl-30 program-heading-prg">
                Our <span className="fw-200">Programs</span>
              </h4>
            </div>
            <div className="program-description">
              <p className="pl-50 program-description-prg">
                {pedagogyDescription}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="left">
                <div className="glry-img">
                  <div className="bg-img tab-img current">
                    <img
                      src={images[currentTab]}
                      alt=""
                      className="programs-img"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 content prgrms-tabs">
              {accordionItems.map((item, index) => (
                <div
                  key={index}
                  className={`mb-50 ${currentTab === item.id ? "current" : ""}`}
                  data-tab={item.id}
                  onMouseEnter={() => handleMouseEnter(item.id)}
                  onClick={() => toggleAccordion(index)}
                >
                  <div className="accordion bord">
                    <div
                      className="item mb-15 wow fadeInUp "
                      data-wow-delay=".1s"
                    >
                      <div className="title">
                        <h6 className="text-u programs-texts">{item.title}</h6>
                        <span className="icoo ">
                          {activeAccordion === index ? "×" : "+"}
                        </span>
                      </div>
                      {activeAccordion === index && (
                        <div>
                          <p className="accorion-infoo bord-thin-lefts">
                            {item.content}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <SideNavigation
        key={menuName}
        isMenuOpen={isMenuOpen}
        handleMenuToggle={handleMenuToggle}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        defaultOpenSubMenu={menuName}
      />
    </div>
  );
}

export default Programs;
