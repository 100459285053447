import React, { useState, useEffect,useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderBackground from "../../../common/SubpagesBGimage";
import { Modal } from "react-bootstrap";
import { HomePageActions } from "../../../Store/Home/action";
import CloseButton from "react-bootstrap/CloseButton";
import Cursor from "../../../common/Cursor";

function ListPageNewsandEvents() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [selectedNews, setSelectedNews] = useState(null);
  const [animateHeading, setAnimateHeading] = useState(false);
  const headingRef = useRef(null);
  const [hasScrolledIntoView, setHasScrolledIntoView] = useState(false);
  const [animateNews, setAnimateNews] = useState(
    Array(4).fill(false) // Array to control animation for each news item
  );
  const newsRefs = useRef([]); // Ref array to track each news item



  const { NewsAndEvents } = useSelector((state) => state.HomePageReducer);

  const handleOpenModal = (news) => {
    if (news.images.length <= 1) {
      setSelectedNews(news);
      setShowModal(true);
    } else {
      navigate("/Gallery", { state: { id: news.id, type: "news" } });
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedNews(null);
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const headingObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setAnimateHeading(true);
        }
      });
    }, options);

    if (headingRef.current) {
      headingObserver.observe(headingRef.current);
    }

    // Cleanup
    return () => {
      if (headingRef.current) {
        headingObserver.unobserve(headingRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const newsObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const index = newsRefs.current.indexOf(entry.target);
          if (index !== -1) {
            setAnimateNews((prev) => {
              const updated = [...prev];
              updated[index] = true;
              return updated;
            });
          }
        }
      });
    }, options);

    newsRefs.current.forEach((ref) => {
      if (ref) {
        newsObserver.observe(ref);
      }
    });

    // Cleanup
    return () => {
      newsRefs.current.forEach((ref) => {
        if (ref) {
          newsObserver.unobserve(ref);
        }
      });
    };
  }, [NewsAndEvents]);

  const handleViewMoreClick = () => {
    navigate("/ListPageNewsandEvents");
  };

  const routeNames = {
    "/newsandevents": { menuName:  "News & Events"  },
  };


  const { menuName, subMenuName } =
    location.state || routeNames[location.pathname] || {};

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };



  return (
    <div>
      <HeaderBackground
        menuName={menuName}
        handleMenuToggle={handleMenuToggle}
      />
    <section className="services-boxs section-padding ">
      <Cursor />
      <div className="container">
        <div className="sec-head mb-20">
          <div className="bord pt-20 bord-thin-top d-flex align-items-center">
            <h2
              ref={headingRef}
              className={`fw-600 ls1 ${
                animateHeading ? "heading-animation" : ""
              }portfolio-text`}
            >
             News & Events
            </h2>
          </div>
        </div>
        <div className="row pt-30">
          {NewsAndEvents?.map((news, index) => (
            <div
              className={`col-lg-3 col-md-6 items d-rotate animated ${
                animateNews[index] ? "news-animation" : ""
              }`}
              key={index}
              ref={(el) => (newsRefs.current[index] = el)} // Set ref for each news item
            >
              <div
                className="item-box bg md-mb50 rotate-text res-text"
                onClick={() => handleOpenModal(news)}
              >
                <div className="icon">
                  <a>
                    <img src={news.thumbnail} alt={`Thumbnail ${index}`} />
                  </a>
                </div>
                <h5 className="mb-15 news-bot-wrap">
                  <a className="news-title">{news.title}</a>
                </h5>
                <p>{news.description.slice(0, 100)}...</p>
              </div>
            </div>
          ))}
        </div>
        {/* <div className="flr mt-30">
          <a
            className="go-more"
            onClick={handleViewMoreClick}
            style={{ cursor: "pointer" }}
          >
            <span className="text">View more</span>
            <span className="icon ti-arrow-circle-right news-texts"></span>
          </a>
        </div> */}
      </div>

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <CloseButton className="close-button " onClick={handleCloseModal} />
        <Modal.Header>
          <Modal.Title className="animate-charcter">
            {selectedNews && selectedNews.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedNews && (
            <>
              {selectedNews.images.length > 0 && (
                <div className="icon">
                  <a className="newsandevents-modal">
                    <div className="">
                      <img
                        className="image-newsandevents"
                        src={selectedNews.images[0].image}
                        alt={selectedNews.images[0].content}
                      />
                      <p className="image-overlay">
                        {selectedNews.images[0].content}
                      </p>
                    </div>
                  </a>
                </div>
              )}
              <h5 className="mt-3"></h5>
              <p>{selectedNews.description}</p>
            </>
          )}
        </Modal.Body>
      </Modal>
    </section>
  </div>
  );
}

export default ListPageNewsandEvents;
